const API_BASE_URL = 'https://lfreturnme-1-0kzj.onrender.com';
export const API_BASE_CMS_URL = 'https://cms-ovbx.onrender.com'

// API endpoint for user sign-in
export const API_SIGNIN_URL = `${API_BASE_URL}/signin`;

// API endpoint for user registration
export const API_SIGNUP_URL = `${API_BASE_URL}/signup`;

// API endpoint for item registration
export const API_REGISTER_ITEM_URL = `${API_BASE_URL}/register-item`;

// API endpoint for password recovery
export const API_FORGOT_PASSWORD_URL = `${API_BASE_URL}/forgot-password`;

// API endpoint for password reset
export const API_RESET_PASSWORD_URL = `${API_BASE_URL}/reset-password`;

// API endpoint for updating item status
export const API_UPDATE_ITEM_STATUS_URL = `${API_BASE_URL}/update-item-status`;

// API endpoint for updating user details
export const API_UPDATE_USER_URL = `${API_BASE_URL}/update-profile`;

// API endpoint for email subscription
export const API_EMAIL_SUBSCRIBER_URL = `${API_BASE_URL}/email-sub`;

export const API_SEND_OTP_URL = `${API_BASE_URL}/api/send-otp`;


export const API_VERIFY_OTP_URL = `${API_BASE_URL}/api/verify-otp`;

export const API_DASHBOARD_URL = `${API_BASE_URL}/dashboard`;


export const CANCLE_SUB_URL = `${API_BASE_URL}/cancel-sub`;


export const SUBSCRIPTION_CHANGE_EMAIL = `${API_BASE_URL}/update-sub`;

export const PAYSTACK_SECRET_KEY = `sk_test_432dc6d3ca1d2fb0ead4459ff21699e95409b2af`


export const API_SUBSCRIPTION_STATUS_URL = "https://api.paystack.co/subscription/"



export const PAYSTACK_PREMIUM = "PLN_ssu2q27n1cns9zj"

export const PAYSTACK_MIDDLE = "PLN_zdpahiss0peooze"

export const PAYSTACK_BASIC = "PLN_6pcgq9h80epdqxg"


export const PUBLIC_KEY = "pk_test_4b20a589e2525178d918fd65c45e77c739d0648b"
