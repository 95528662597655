import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAYSTACK_PREMIUM, PAYSTACK_MIDDLE, PAYSTACK_BASIC, PUBLIC_KEY } from '../service/apiEndpoints';

const PaymentPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { tag, selectedPlan } = location.state || {};

    // Mapping plan labels to Paystack plan codes
    const planCodes = {
        Premium: PAYSTACK_PREMIUM, // Premium plan code
        Middle: PAYSTACK_MIDDLE, // Middle plan code
        Basic: PAYSTACK_BASIC,  // Basic plan code
    };

    // Function to format the email with the tag_id
    const formatEmailWithTagId = (email, tagId) => {
        const [user, domain] = email.split('@');
        return `${user}+${tagId}@${domain}`;
    };

    // Format the email to include the tag_id
    const formattedEmail = formatEmailWithTagId(tag.email_address, tag.tag_id);

    const paystackConfig = {
        key: PUBLIC_KEY,
        email: formattedEmail, // Customer email with tag_id
        plan: planCodes[selectedPlan.label], // Select the correct plan code based on the plan label
        ref: `${Date.now()}-${tag.tag_id}`, // Unique reference
        metadata: {
            custom_fields: [
                {
                    display_name: 'Tag ID',
                    variable_name: 'tag_id',
                    value: tag.tag_id,
                },
            ],
        },
    };

    const initiatePayment = () => {
        const handler = window.PaystackPop.setup({
            key: paystackConfig.key,
            email: paystackConfig.email,
            ref: paystackConfig.ref,
            plan: paystackConfig.plan, // This links the customer to the selected subscription plan
            metadata: paystackConfig.metadata,
            callback: (response) => {
                // console.log('Subscription successful:', response);
                alert('Subscription successful');
                navigate('/dashboard');
            },
            onClose: () => {
                console.log('Payment window closed');
                alert('Payment was not completed');
            },
        });
        handler.openIframe();
    };

    useEffect(() => {
        // Ensure Paystack script is loaded
        const script = document.createElement('script');
        script.src = 'https://js.paystack.co/v1/inline.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <h1>Payment for Subscription</h1>
            <p>Tag ID: {tag.tag_id}</p>
            <p>Plan: {selectedPlan.label}</p>
            <p>Amount: {selectedPlan.amount} NGN</p>
            <p>Email: {formattedEmail}</p>  {/* Display the formatted email */}

            {/* Pay Now Button */}
            <button
                onClick={initiatePayment}
                className='cta-button'>
                Subscribe Now
            </button>
            <br />
            <br />
            {/* Back Button */}
            <button
                onClick={() => navigate(-1)}
                style={{ padding: '10px 20px', marginRight: '10px', background: '#ccc', color: '#000', border: 'none', }}>
                Back
            </button>
        </div>
    );
};

export default PaymentPage;
